.form{
    display: flex;
    flex-direction: column;
    gap: 12px;


    &-label{
        width: 100%;
    }

    &-input{
        width: 100%;
        padding: 19px 19px;
        background-color: transparent;
        border: 1px solid rgba(248, 248, 248, 0.6);
        color: $white;
        font-size: 14px;
        line-height: 1.14;
        font-weight: 400;
        min-width: 105px;
        border-radius: 50px;

        &::placeholder{
            color: rgba(248, 248, 248, 0.6);
            font-size: 14px;
            line-height: 1.14;
            font-weight: 400;
        }

        &.textarea {
            min-height: 150px;
            resize: none;
            position: relative;

            &::placeholder{
                position: absolute;
                bottom: 17px;
                left: 30px;
            }
        }
    }


}