.header{
    padding-top: 30px;
    padding-bottom:20px;

    &-nav{
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include tablet{
            align-items: flex-end;
        }
    }
}


