html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: $white;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
body.hidden {
  overflow: hidden;
}

li{
  &:last-child{
    margin-bottom: 0!important;
  }
}

address {
  font-style: normal;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

section{
  padding: 40px 0;

  @include desktop{
      padding: 50px 0;
  }

  &.hero{
    padding-top:50px;
  }
}

img{
  width: 100%;
}

p{
  &.before{
    padding-top: 32px;
    border-top: 1px solid $white;
  }
}

.border {
  padding-bottom: 20px;
  border-bottom: 1px solid $white;
}

section {
  .border{
    padding-bottom: 30px;
  }
}



// ------------------------------ navigation 

.navigation{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  gap: 30px;
  @include tablet{
    flex-direction: row;
    align-items: flex-end;
  }
}


// ------------------------------  logo 
.logo{
  font-size: 26px;
  line-height: 1.2;
  font-weight: 600;
  color: #f7f7f7;
  text-transform: uppercase;
  transition: $transition;

  &:hover,
    &:focus {
        background: $gradient;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
    }
  
    &:active {
      color: $white;
    }

}


// ------------------------------ links 

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  font-size: 14px;
  font-weight: 400;
  color: $white;

  transition: $transition;

  background-color: transparent;
  
  &:hover,&:focus{
    text-decoration: underline;
    color: $gradient;
  }
  &:active{
    color: $white;
    text-decoration: none;
  }
}


// ------------------------------ headings 1  2  3

.heading1{
  font-size: 52px;
  font-weight: 400;
  line-height: 1.1;
      max-width: 900px;
  @include tablet{
    font-size: 84px;
  }
  @include desktop{
    font-size: 120px;
  }
}
.heading2 {
    font-size: 36px;
    font-weight: 400;
    line-height: 1.1;

    @include tablet {
        font-size: 70px;
    }
}
.heading2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
}



// ------------------------------ btn hover focus active 
.hfa{
  transition: $transition;

  &:hover,&:focus{
    border: 1px solid $gradient;
    color: $gradient;
    background-color: transparent;
  }
  &:active{
    color: $white;
    border: 1px solid $gradient;
    background-color: transparent;
  }
}

// opacity



//margin

.m-0{
  margin: 0;
}
.mb-16{
  margin-bottom: 16px;
}
.mb-24{
  margin-bottom: 24px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}

//btn
.btn{
  width: 100%;
  margin-top: 8px;
  padding: 20px 24px;
  background: $gradient;
  color: $white;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  // border: 1px solid $gradient;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:2px solid $error
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden 
// .header-nav, img {
//   &.mobile-hidden{
//     display: none;
//     @include tablet{
//       display: flex;
//     }
//   }
// }
br, .header-nav, img{
  &.tablet-hidden {
    @include tablet{
      display: none;
    }
  }
}
// .header-nav.tablet-hidden {
//   @include desktop {
//     display: flex;
//   }
// }
// img{
//   &.desktop-hidden {

//       @include desktop {
//         display: none;
//       }
//     }
// }


