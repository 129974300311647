.hero{
    
    img{
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        @include tablet {
            max-width: 696px;
            margin-right: 0;
        }
    }


}

.clients{
    .descr{
        max-width: 488px;
    }
}

.about-us{
    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;

        @include desktop{
            flex-direction: row-reverse;
            align-self: center;
            justify-content: space-between;
            gap: 50px;
        }
    }

    img{
        max-width: 357px;
    }

}
