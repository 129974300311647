.footer{
    padding-bottom: 30px;


    & .navigation{
        flex-direction: column;
        gap: 40px;

        @include tablet{
            flex-direction: row;
        }

    }

                        & p {
                            color: rgba(248, 248, 248, 0.8);
                            padding-top: 20px;
                            text-align: start;
            
                            @include tablet {
                                text-align: end;
                            }
                        }
    

        & ul{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 20px;

            @include tablet{
                align-items: flex-end;
            }
            
        }
    }
    

