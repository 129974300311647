// font family
$main-font: "Montserrat", sans-serif;


// bg
$main-bg:#0F0F0F;
$gradient: linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
// text

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

$white: #F3F3F3;
$black:#111111;
$accent:#A9C52F;
$second:#228896;
$error:#f27508;

// modal
$modal-bgd: #2C2C2C;
$burger-btn-stroke: #292D32;


