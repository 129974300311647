@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 360px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    padding: 0 40px;
    max-width: 1280px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  fill: currentColor;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  background-color: #0F0F0F;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #F3F3F3;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

li:last-child {
  margin-bottom: 0 !important;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

section {
  padding: 40px 0;
}
@media screen and (min-width: 1280px) {
  section {
    padding: 50px 0;
  }
}
section.hero {
  padding-top: 50px;
}

img {
  width: 100%;
}

p.before {
  padding-top: 32px;
  border-top: 1px solid #F3F3F3;
}

.border {
  padding-bottom: 20px;
  border-bottom: 1px solid #F3F3F3;
}

section .border {
  padding-bottom: 30px;
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}
@media screen and (min-width: 768px) {
  .navigation {
    flex-direction: row;
    align-items: flex-end;
  }
}

.logo {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 600;
  color: #f7f7f7;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.logo:hover, .logo:focus {
  background: linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.logo:active {
  color: #F3F3F3;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  font-size: 14px;
  font-weight: 400;
  color: #F3F3F3;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  background-color: transparent;
}
.link:hover, .link:focus {
  text-decoration: underline;
  color: linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
}
.link:active {
  color: #F3F3F3;
  text-decoration: none;
}

.heading1 {
  font-size: 52px;
  font-weight: 400;
  line-height: 1.1;
  max-width: 900px;
}
@media screen and (min-width: 768px) {
  .heading1 {
    font-size: 84px;
  }
}
@media screen and (min-width: 1280px) {
  .heading1 {
    font-size: 120px;
  }
}

.heading2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 1.1;
}
@media screen and (min-width: 768px) {
  .heading2 {
    font-size: 70px;
  }
}

.heading2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.hfa:hover, .hfa:focus {
  border: 1px solid linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
  color: linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
  background-color: transparent;
}
.hfa:active {
  color: #F3F3F3;
  border: 1px solid linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
  background-color: transparent;
}

.m-0 {
  margin: 0;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.btn {
  width: 100%;
  margin-top: 8px;
  padding: 20px 24px;
  background: linear-gradient(90deg, #6a2cfa 0%, #f0ae30 100%);
  color: #F3F3F3;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.just-validate-error-field.form-input {
  border: 2px solid #f27508;
}

.just-validate-error-label {
  display: none;
}

@media screen and (min-width: 768px) {
  br.tablet-hidden, .header-nav.tablet-hidden, img.tablet-hidden {
    display: none;
  }
}

.header {
  padding-top: 30px;
  padding-bottom: 20px;
}
.header-nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .header-nav {
    align-items: flex-end;
  }
}

.footer {
  padding-bottom: 30px;
}
.footer .navigation {
  flex-direction: column;
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .footer .navigation {
    flex-direction: row;
  }
}
.footer p {
  color: rgba(248, 248, 248, 0.8);
  padding-top: 20px;
  text-align: start;
}
@media screen and (min-width: 768px) {
  .footer p {
    text-align: end;
  }
}
.footer ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .footer ul {
    align-items: flex-end;
  }
}

/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */
/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
  background-size: 100% 100%;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: red;
}
.hystmodal__close:focus {
  color: red;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
.hystmodal.hystmodal--cookie .hystmodal__wrap {
  justify-content: flex-end;
}
.hystmodal.hystmodal--cookie .hystmodal__window {
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 40px;
  width: 100%;
  max-width: 720px;
  margin-bottom: 30px;
}
.hystmodal.hystmodal--cookie .hystmodal__window .hystmodal__close {
  display: none;
}
.hystmodal.hystmodal--cookie .hystmodal__window img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}
.hystmodal.hystmodal--cookie .hystmodal__window .btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .btn-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
.hystmodal.hystmodal--cookie .hystmodal__window .btn {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .btn {
    max-width: 193px;
  }
}
.hystmodal.hystmodal--cookie .hystmodal__window .politico {
  padding: 19px 59px;
  border: 1px solid #F3F3F3;
  background-color: transparent;
  color: #F8F8F8;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  border-radius: 50px;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .hystmodal.hystmodal--cookie .hystmodal__window .politico {
    max-width: 273px;
  }
}

/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: black;
  padding: 50px;
}

.hero img {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .hero img {
    max-width: 696px;
    margin-right: 0;
  }
}

.clients .descr {
  max-width: 488px;
}

.about-us .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}
@media screen and (min-width: 1280px) {
  .about-us .content-wrapper {
    flex-direction: row-reverse;
    align-self: center;
    justify-content: space-between;
    gap: 50px;
  }
}
.about-us img {
  max-width: 357px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.form-label {
  width: 100%;
}
.form-input {
  width: 100%;
  padding: 19px 19px;
  background-color: transparent;
  border: 1px solid rgba(248, 248, 248, 0.6);
  color: #F3F3F3;
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
  min-width: 105px;
  border-radius: 50px;
}
.form-input::placeholder {
  color: rgba(248, 248, 248, 0.6);
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
}
.form-input.textarea {
  min-height: 150px;
  resize: none;
  position: relative;
}
.form-input.textarea::placeholder {
  position: absolute;
  bottom: 17px;
  left: 30px;
}