// @mixin font($fw, $fs, $lh, $ls) {
//     font-weight: $fw;
//     font-size: $fs + px;
//     line-height: $lh;
//     letter-spacing: $ls + em;
// }

@mixin mobile {
    @media screen and (max-width: 767.98px) {
        @content;
    }
}

@mixin tablet {

    @media screen and (min-width: 768px) { 
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1280px) { 
        @content;
    }
}

@mixin retina {
    @media screen and (min-device-pixel-ratio: 2),
      screen and (-moz-min-device-pixel-ratio: 2),
      screen and (-o-min-device-pixel-ratio: 2/1),
      screen and (-webkit-min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      @content;
    }
}