
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include mobile {
    max-width: 360px;
  }

  @include tablet {
    max-width: 768px;
  }

  @include desktop {
    padding: 0 40px;
    max-width: 1280px;
  }
}